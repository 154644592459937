import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { api, submitForm } from '../Modul';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            JmlData: 1,
            Judul: "",
            Konten: { "about": "", link: [], sosmed: [] },
            Status: 1,
            NoUrut: "1",
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save"
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data konten", Menu: "Footer", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            if (sql.j > 0) {
                this.setState({
                    Data: sql.data,
                    JmlData: sql.j,
                    JmlData: 1,
                    Judul: "",
                    Konten: { "about": "", link: [], sosmed: [] },
                    Status: 1,
                    NoUrut: "1",
                    ID: 0,
                    saveCls: "fas fa-save"
                });
            } else {
                this.setState({
                    Data: [{ data: "", }],
                    JmlData: sql.j,
                    JmlData: 1,
                    Judul: "",
                    Konten: { "about": "", link: [], sosmed: [] },
                    Status: 1,
                    NoUrut: "1",
                    ID: 0,
                    saveCls: "fas fa-save"
                });
            }
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDetail(i) {
        let Detail = this.state.Data[i];
        let Konten = JSON.parse(Detail.Konten);
        this.setState({
            Judul: Detail.Judul,
            Konten: Konten,
            Status: Detail.Status,
            NoUrut: Detail.NoUrut,
            ID: Detail.ID,
            Detail: Detail
        });

        let modal = new window.bootstrap.Modal(document.getElementById('modalKontenText'), {});
        modal.show("#modalKontenText");
    }

    handleAddLink(obj) {
        let Konten = this.state.Konten;
        Konten.link.push(obj);
        this.setState({ Konten: Konten });
    }

    handleChangeLink(i, obj, val) {
        let Konten = this.state.Konten;
        Konten.link[i][obj] = val;
        this.setState({ Konten: Konten });
    }

    handleDeleteLink(i) {
        let Konten = this.state.Konten;
        Konten.link.splice(i, 1);
        this.setState({ Konten: Konten });
    }

    handleAddSosmed(obj) {
        let Konten = this.state.Konten;
        Konten.sosmed.push(obj);
        this.setState({ Konten: Konten });
    }

    handleChangeSosmed(i, obj, val) {
        let Konten = this.state.Konten;
        Konten.sosmed[i][obj] = val;
        this.setState({ Konten: Konten });
    }

    handleDeleteSosmed(i) {
        let Konten = this.state.Konten;
        Konten.sosmed.splice(i, 1);
        this.setState({ Konten: Konten });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Konten Kelas</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divTableAbsen">
                            <table className="table table-striped">
                                <thead className='bg-thead'>
                                    <tr>
                                        <th width="75px"></th>
                                        <th onClick={() => this.handleSort("Posisi")}>Posisi</th>
                                        <th onClick={() => this.handleSort("Judul")}>Judul</th>
                                        <th onClick={() => this.handleSort("NoUrut")}>Nomer</th>
                                        <th onClick={() => this.handleSort("Status")}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Data.map((tr, i) => {
                                            let Status = tr.Status == 1 ? <span className="badge text-bg-primary">Aktif</span> : <span className="badge text-bg-danger">Tidak Aktif</span>;
                                            if (this.state.JmlData > 0) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <button className="btn" type="button" tooltip="Edit" onClick={() => this.handleDetail(i)}>
                                                                <i className="fas fa-pen-square"></i>
                                                            </button>
                                                        </td>
                                                        <td>{tr.Posisi}</td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{tr.NoUrut}</td>
                                                        <td>{Status}</td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={i}>
                                                        <td colSpan={6}>Tidak ada data</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Untuk text */}
                <div className="modal fade" id="modalKontenText" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Konten.about}
                                            onChange={(e) => {
                                                this.setState({
                                                    Konten: {
                                                        ...this.state.Konten,
                                                        about: e,
                                                    }
                                                });
                                            }}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <h6>Link</h6>
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddLink({ link: "", text: "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Text</th>
                                                    <th>Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Konten.link.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteLink(ii)}></i></td>
                                                            <td>
                                                                <input type="text" value={tr.text} className='form-control form-control-sm' onChange={(e) => this.handleChangeLink(ii, 'text', e.target.value)} required />
                                                            </td>
                                                            <td>
                                                                <input type="link" value={tr.link} className='form-control form-control-sm' onChange={(e) => this.handleChangeLink(ii, 'link', e.target.value)} required />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <h6>Sosmed</h6>
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddSosmed({ icon: "", link: "", text: "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Icon</th>
                                                    <th>Text</th>
                                                    <th>Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Konten.sosmed.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteSosmed(ii)}></i></td>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => this.handleChangeSosmed(ii, 'icon', e.target.value)} required />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.text} className='form-control form-control-sm' onChange={(e) => this.handleChangeSosmed(ii, 'text', e.target.value)} required />
                                                            </td>
                                                            <td>
                                                                <input type="link" value={tr.link} className='form-control form-control-sm' onChange={(e) => this.handleChangeSosmed(ii, 'link', e.target.value)} required />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <input type='hidden' name='Konten' value={JSON.stringify(this.state.Konten)} />
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Batal</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
