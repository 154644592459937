import React, { Fragment } from 'react';
import { Token, compressImageBase64, resizeImageBase64, host, api, pesan, submitForm } from '../Modul';
import Pagination from '../component/Pagination';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Detail: {},
            JmlData: 0,
            Judul: "",
            Konten: [],
            Galeri: [],
            Status: 1,
            NoUrut: "1",
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save",
            btnSubmit: false
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
            let clsCard = document.getElementsByClassName("card");
            for (let i = 0; i < clsCard.length; i++) {
                clsCard[i].classList.add("text-bg-dark");
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data galeri", Menu: "Galeri", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            if (sql.j > 0) {
                this.setState({ Data: sql.data, JmlData: sql.j, Detail: {}, Galeri: [], Judul: "", Konten: [], Status: 1, NoUrut: "1", ID: 0, saveCls: "fas fa-save", btnSubmit: false });
            } else {
                this.setState({ Data: [{ data: "", }], JmlData: 0, Detail: {}, Judul: "", Konten: [], Galeri: [], Status: 1, NoUrut: "1", ID: 0, saveCls: "fas fa-save", btnSubmit: false });
            }
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(ID) {
        let sql = await api("konten", { act: "detail galeri", ID: ID });
        if (sql.status == "sukses") {
            if (ID == "baru") {
                this.setState({
                    Judul: "",
                    Konten: [],
                    Status: 1,
                    NoUrut: 2,
                    ID: "",
                    ArrKonten: [],
                    Detail: {},
                    saveCls: "fas fa-save",
                    btnSubmit: false
                });
            } else {
                let Detail = sql.data;
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: JSON.parse(Detail.Konten),
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail,
                    saveCls: "fas fa-save",
                    btnSubmit: false
                });
            }

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayGaleri'), {});
            modal.show("#modalKontenArrayGaleri");
        }
    }

    handleChangeArrayKonten(i, arr, val) {
        let data = this.state.ArrKonten;
        data[i][arr] = val;
        let Detail = this.state.Detail;
        Detail.Konten = JSON.stringify(data);
        this.setState({ Detail: Detail, Konten: JSON.stringify(data) });
    }

    handleAddArrayKonten(obj) {
        let data = this.state.ArrKonten;
        data.push(obj);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    handleDeleteArrayKonten(i) {
        let data = this.state.Konten;
        data.splice(i, 1);
        this.setState({ Konten: data })
    }

    openFile(e, ii) {
        let files = e.target.files;
        let Komten = this.state.Konten;
        let ct = Komten.length;
        for (let i = 0; i < files.length; i++) Komten.push({ foto: imgLoading, nama: `Gambar ${i}`, keterangan: `Loading` });
        this.setState({ Konten: Komten });
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            reader.onload = async () => {
                try {
                    let baru = await compressImageBase64(reader.result, 'jpeg', 0.8);
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = await resizeImageBase64(baru, height / 5, width / 5);
                        this.handleAddFile(ct, { foto: newRes, nama: `Gambar ${i}`, keterangan: `Width : ${width} Heright : ${height}` });
                        ct++;
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    handleAddFile(ii, file) {
        console.log(ii);
        let Konten = this.state.Konten;
        Konten[ii] = file;
        this.setState({ Konten: Konten })
    }
    
    async handleDetailGaleri(ID) {
        this.setState({
            Galeri: [
                { foto: imgLoading, nama: "Loading" },
                { foto: imgLoading, nama: "Loading" },
                { foto: imgLoading, nama: "Loading" },
                { foto: imgLoading, nama: "Loading" },
            ]
        });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDetail'), {});
        modal.show("#modalDetail");
        let sql = await api("konten", { act: "detail galeri", ID: ID });
        if (sql.status == "sukses") this.setState({ Galeri: JSON.parse(sql.data.Konten) });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Galeri</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Konten Galeri</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divTableAbsen">
                            <table className="table table-striped">
                                <thead className='bg-thead'>
                                    <tr>
                                        <th width="150px"></th>
                                        <th onClick={() => this.handleSort("Judul")}>Judul</th>
                                        <th onClick={() => this.handleSort("NoUrut")}>Nomer</th>
                                        <th onClick={() => this.handleSort("Status")}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Data.map((tr, i) => {
                                            let Status = tr.Status == 1 ? <span className="badge text-bg-primary">Aktif</span> : <span className="badge text-bg-danger">Tidak Aktif</span>;
                                            if (this.state.JmlData > 0) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <button className="btn" type="button" tooltip="Edit" onClick={() => this.handleDetail(tr.ID)}>
                                                                <i className="fas fa-pen-square"></i>
                                                            </button>
                                                            <button className="btn" type="button" tooltip="Detail" onClick={() => this.handleDetailGaleri(tr.ID)}>
                                                                <i className="fas fa-info"></i>
                                                            </button>
                                                            <button className="btn" type="button" tooltip="Hapus" data-bs-toggle="modal" data-bs-target="#modalDelete" onClick={() => this.setState({ ID: tr.ID, saveCls: "fas fa-trash" })}>
                                                                <i className="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{tr.NoUrut}</td>
                                                        <td>{Status}</td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={i}>
                                                        <td colSpan={4}>Tidak ada data</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk array Galeri */}
                <div className="modal fade" id="modalKontenArrayGaleri" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: (e) => this.handleMain() })} className='needs-validation' noValidate>
                            
                            <input type='hidden' name="Menu" value="Galeri" />
                            <input type='hidden' name="Posisi" value="Body" />
                            <input type='hidden' name="KontenType" value="ArrayGaleri" />
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah konten" : "edit konten"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <br></br>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => document.getElementById("edtFile").click()}>Tambah Gambar</button>
                                    <input type="file" id="edtFile" accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e)} multiple={true} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='table-dark'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Konten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td><img src={images} /></td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'keterangan', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayGaleri'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSubmit}><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk Detail */}
                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Galeri</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row">
                                        {
                                            this.state.Galeri.map((dd, ii) => {
                                                return (
                                                    <div className="col" key={ii}>
                                                        <div className="card mb-1" style={{ maxWidth: '120px' }}>
                                                            <img src={dd.foto} className="card-img-top img-fluid" alt={dd.nama} />
                                                            <div className="card-body">
                                                                <h5 className="card-title">{dd.nama}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                                
                                <input type='hidden' name="act" value="hapus konten" />
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Konfirmasi Hapus data</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini?</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className={this.state.saveCls}></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;