import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImageBase64, resizeImageBase64, api, submitForm } from '../Modul';
import Pagination from '../component/Pagination';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [{ "Posisi": "loading" }, { "Posisi": "loading" }, { "Posisi": "loading" }],
            Detail: {},
            JmlData: 0,
            Judul: "",
            Konten: "",
            Status: 1,
            NoUrut: "1",
            ArrKonten: [],
            dataAboutTeam: {},
            ArrTeam: [],
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save"
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data konten", Menu: "Home", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            if (sql.j > 0) {
                this.setState({ Data: sql.data, JmlData: sql.j, Detail: {}, JmlData: 1, Judul: "", Konten: "", Status: 1, NoUrut: "1", ArrKonten: [], dataAboutTeam: {}, ArrTeam: [], ID: 0, saveCls: "fas fa-save" });
            } else {
                this.setState({ Data: [{ data: "", }], JmlData: sql.j, Detail: {}, JmlData: 1, Judul: "", Konten: "", Status: 1, NoUrut: "1", ArrKonten: [], dataAboutTeam: {}, ArrTeam: [], ID: 0, saveCls: "fas fa-save" });
            }
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDetail(i) {
        let Detail = this.state.Data[i];
        if (Detail.KontenType == "Text") {
            this.setState({
                Judul: Detail.Judul,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenText'), {});
            modal.show("#modalKontenText");
        } else if (Detail.KontenType == "ArrayIcon") {
            let data = JSON.parse(Detail.Konten);
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                ArrKonten: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayIcon'), {});
            modal.show("#modalKontenArrayIcon");
        } else if (Detail.KontenType == "ArrayTeam") {
            let data = JSON.parse(Detail.Konten);
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                ArrKonten: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayTeam'), {});
            modal.show("#modalKontenArrayTeam");
        } else if (Detail.KontenType == "ArrayImages") {
            let data = JSON.parse(Detail.Konten);
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                ArrKonten: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayImages'), {});
            modal.show("#modalKontenArrayImages");
        } else if (Detail.KontenType == "ArrayProduk") {
            let data = JSON.parse(Detail.Konten);
            let team = data.produk;
            this.setState({
                Judul: Detail.Judul,
                Konten: data,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                ArrTeam: team,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayProduk'), {});
            modal.show("#modalKontenArrayProduk");
        } else if (Detail.KontenType == "ArrayCard") {
            let data = JSON.parse(Detail.Konten);
            console.log(data);
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                ArrKonten: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayCard'), {});
            modal.show("#modalKontenArrayCard");
        } else if (Detail.KontenType == "AboutTeam") {
            let data = JSON.parse(Detail.Konten);
            let team = data.team;
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                ArrTeam: team,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenAboutTeam'), {});
            modal.show("#modalKontenAboutTeam");
        } else if (Detail.KontenType == "ArraySejarah") {
            let data = JSON.parse(Detail.Konten);
            let team = data.data;
            this.setState({
                Judul: Detail.Judul,
                Konten: data,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                ArrTeam: team,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArraySejarah'), {});
            modal.show("#modalKontenArraySejarah");
        } else if (Detail.KontenType == "ArrayQuotes") {
            let data = JSON.parse(Detail.Konten);
            let team = data.quotes;
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                ArrTeam: team,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayQuotes'), {});
            modal.show("#modalKontenArrayQuotes");
        } else if (Detail.KontenType == "CardJoin") {
            let data = JSON.parse(Detail.Konten);
            this.setState({
                Judul: Detail.Judul,
                Konten: Detail.Konten,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenCardJoin'), {});
            modal.show("#modalKontenCardJoin");
        } else if (Detail.KontenType == "ArrayObject") {
            let data = JSON.parse(Detail.Konten);
            console.log(data);
            this.setState({
                Judul: Detail.Judul,
                Konten: data,
                Status: Detail.Status,
                NoUrut: Detail.NoUrut,
                ID: Detail.ID,
                dataAboutTeam: data,
                Detail: Detail
            });

            let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayObject'), {});
            modal.show("#modalKontenArrayObject");
        }
    }

    handleChangeArrayKonten(i, arr, val) {
        let data = this.state.ArrKonten;
        data[i][arr] = val;
        let Detail = this.state.Detail;
        Detail.Konten = JSON.stringify(data);
        this.setState({ Detail: Detail, Konten: JSON.stringify(data) });
    }

    handleAddArrayKonten(obj) {
        let data = this.state.ArrKonten;
        data.push(obj);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    handleDeleteArrayKonten(i) {
        let data = this.state.ArrKonten;
        data.splice(i, 1);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    openFile(e, ii, from = "konten") {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        if (from == "konten") {
                            this.handleChangeArrayKonten(ii, "foto", newRes);
                        } else if (from == "quotes") {
                            let data = this.state.dataAboutTeam;
                            data.background = newRes;
                            this.setState({ Konten: data });
                        } else {
                            this.handleChangeArrAboutTeam(ii, "foto", newRes);
                        }
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };

            reader.readAsDataURL(file);
        }
    }

    handlePilihBG(e, compress = true) {
        let data = this.state.dataAboutTeam;
        data.background = imgLoading;
        this.setState({ Konten: data });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        data.background = newRes;
                        this.setState({ Konten: data });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    handleChangeAboutTeam(obj, val) {
        let data = this.state.dataAboutTeam;
        data[obj] = val;
        this.setState({ dataAboutTeam: data, Konten: data });
    }

    handleChangeArrAboutTeam(i, obj, val) {
        let team = this.state.ArrTeam;
        team[i][obj] = val;
        let data = this.state.dataAboutTeam;
        data.team = team;
        this.setState({ dataAboutTeam: data, ArrTeam: team, Konten: data });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Konten Home</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divTableAbsen">
                            <table className="table table-striped">
                                <thead className='bg-thead'>
                                    <tr>
                                        <th></th>
                                        <th onClick={() => this.handleSort("Posisi")}>Posisi</th>
                                        <th onClick={() => this.handleSort("Konten")}>Judul</th>
                                        <th onClick={() => this.handleSort("Konten")}>Konten</th>
                                        <th onClick={() => this.handleSort("NoUrut")}>Nomer</th>
                                        <th onClick={() => this.handleSort("Status")}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Data.map((tr, i) => {
                                            let Status = tr.Status == 1 ? <span className="badge text-bg-primary">Aktif</span> : <span className="badge text-bg-danger">Tidak Aktif</span>;
                                            let Konten = <span dangerouslySetInnerHTML={{ __html: tr.Konten }} />;
                                            if (tr.KontenType == "ArrayIcon") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <i className={dd.icon}></i>
                                                        <span>{dd.text}</span>
                                                    </div>)
                                                    i++;
                                                }
                                            } else if (tr.KontenType == "ArrayTeam") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <img src={dd.foto} width="50px" />
                                                        <span>{dd.nama}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "ArrayImages") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <img src={dd.foto} width="50px" />
                                                        <span>{dd.nama}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "ArrayProduk") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data.produk) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <img src={dd.foto} width="50px" />
                                                        <span>{dd.nama}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "ArrayCard") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <i className={dd.icon}></i>
                                                        <span>{dd.judul}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "AboutTeam") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data.team) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <img src={dd.foto} width="50px" />
                                                        <span>{dd.nama}</span>
                                                        <span>{dd.bagian}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "ArraySejarah") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data.data) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <b>{dd.type}</b>
                                                        <span>{dd.judul}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "ArrayQuotes") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                let i = 0;
                                                for (let dd of data.quotes) {
                                                    Konten.push(<div className='d-flex gap-2' key={i}>
                                                        <span>{dd.oleh}</span>
                                                        <span>{dd.quotes}</span>
                                                    </div>)
                                                    i++
                                                }
                                            } else if (tr.KontenType == "CardJoin") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                Konten.push(<div className='d-flex gap-2'>
                                                    <b>{data.judul}</b>
                                                    <span>{data.keterangan}</span>
                                                </div>)
                                            } else if (tr.KontenType == "ArrayObject") {
                                                Konten = [];
                                                let data = JSON.parse(tr.Konten);
                                                Konten.push(<div className='d-flex gap-2'>
                                                    <b>{data.judul}</b>
                                                    <span>{data.keterangan}</span>
                                                </div>)
                                            }

                                            if (this.state.JmlData > 0) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <button className="btn" type="button" tooltip="Edit" onClick={() => this.handleDetail(i)}>
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                        <td>{tr.Posisi}</td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{Konten}</td>
                                                        <td>{tr.NoUrut}</td>
                                                        <td>{Status}</td>
                                                    </tr>
                                                )
                                            } else {
                                                if (this.state.Data.length == 0) {
                                                    return (
                                                        <tr key={i}>
                                                            <td colSpan={6}>Tidak ada data</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (<tr>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                    </tr>)
                                                }
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk text */}
                <div className="modal fade" id="modalKontenText" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Detail.Konten}
                                            onChange={(e) => this.setState({ Detail: { ...this.state.Detail, Konten: e } })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type='hidden' name='Konten' value={this.state.Detail.Konten} />
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Batal</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Icon */}
                <div className="modal fade" id="modalKontenArrayIcon" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalArrayIcon", crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ icon: "", text: "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Ikon</th>
                                                    <th>Text</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'icon', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.text} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'text', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayIcon'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Team */}
                <div className="modal fade" id="modalKontenArrayTeam" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalArrayTeam", crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ "nama": "", "bagian": "", "foto": "", "fb": "", "ig": "", "email": "", "telp": "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Bagian</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                    <th>FB</th>
                                                    <th>IG</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.bagian} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'bagian', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.telp} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'telp', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.email} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'email', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.fb} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'fb', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.ig} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'ig', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayTeam'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Images */}
                <div className="modal fade" id="modalKontenArrayImages" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalArrayImages", crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ "foto": "", "nama": "", "keterangan": "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.bagian} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'bagian', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayImages'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Card */}
                <div className="modal fade" id="modalKontenArrayCard" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Ikon</th>
                                                    <th>Judul</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'icon', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.judul} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'judul', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'keterangan', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayCard'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk abour team */}
                <div className="modal fade" id="modalKontenAboutTeam" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.dataAboutTeam)} />
                                    <h4>Konten</h4>
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.judul} onChange={(e) => this.handleChangeAboutTeam('judul', e.target.value)} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="number" className='form-control' alue={this.state.dataAboutTeam.keterangan} onChange={(e) => this.handleChangeAboutTeam('keterangan', e.target.value)} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Bagian</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                    <th>FB</th>
                                                    <th>IG</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrTeam.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii, "AboutTeam")} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.bagian} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'bagian', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.telp} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'telp', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.email} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'email', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.fb} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'fb', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.ig} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrAboutTeam(ii, 'ig', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalAboutTeam'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Sejarah */}
                <div className="modal fade" id="modalKontenArraySejarah" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Judul</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrTeam.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.type} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.data[ii].type = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.judul} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.data[ii].judul = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.data[ii].keterangan = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.dataAboutTeam.background == "" ? imgUpload : this.state.dataAboutTeam.background} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArraySejarah'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Produk */}
                <div className="modal fade" id="modalKontenArrayProduk" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.keterangan} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.keterangan = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => {
                                        let data = this.state.dataAboutTeam;
                                        data.produk.push({ "foto": "", "keterangan": "", "nama": "", "rating": 5, "faforit": true, "harga": 10000, "terjual": "100" });
                                        this.setState({ Konten: data });
                                    }}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Rating</th>
                                                    <th>Harga</th>
                                                    <th>Faforit</th>
                                                    <th>Terjual</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrTeam.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.produk[ii].nama = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="number" value={tr.rating} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.produk[ii].rating = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.harga} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.produk[ii].harga = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id={"chkFavorit" + ii} onChange={(e) => {
                                                                        let data = this.state.dataAboutTeam;
                                                                        data.produk[ii].faforit = e.target.checked == true ? 1 : 0;
                                                                        this.setState({ Konten: data });
                                                                    }} checked={tr.faforit} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.terjual} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.produk[ii].terjual = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.produk[ii].keterangan = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Quotes */}
                <div className="modal fade" id="modalKontenArrayQuotes" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan Quotes</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.judul} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.judul = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Oleh</th>
                                                    <th>Siapa</th>
                                                    <th>Quotes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrTeam.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.oleh} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.quotes[ii].oleh = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.title} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.quotes[ii].title = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.quotes} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.dataAboutTeam;
                                                                    data.quotes[ii].quotes = e.target.value;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <p></p>
                                        <div className='form-group'>
                                            <label>Background Images</label>
                                            <label className='d-flex justify-content-center align-items-center'>
                                                <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                                <img src={this.state.dataAboutTeam.background == "" ? imgUpload : this.state.dataAboutTeam.background} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayQuotes'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Card Join */}
                <div className="modal fade" id="modalKontenCardJoin" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Judul Dua</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.judul} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.judul = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.keterangan} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.keterangan = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.dataAboutTeam.background == "" ? imgUpload : this.state.dataAboutTeam.background} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayQuotes'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                {/* Untuk array Array Object */}
                <div className="modal fade" id="modalKontenArrayObject" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.keterangan} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.keterangan = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <h5>Form dalam banner</h5>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusForm" checked={this.state.dataAboutTeam.isForm} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.isForm = e.target.checked == true ? 1 : 0;
                                            this.setState({ Konten: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="chkStatusForm">Aktif</label>
                                    </div>
                                    <div className='form-group'>
                                        <label>Judul Form</label>
                                        <input type="text" className='form-control' value={this.state.dataAboutTeam.judulForm} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.judulForm = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.keteranganForm} onChange={(e) => {
                                            let data = this.state.dataAboutTeam;
                                            data.keteranganForm = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e, false)} />
                                            <img src={this.state.dataAboutTeam.background == "" ? imgUpload : this.state.dataAboutTeam.background} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayObject'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
